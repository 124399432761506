@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.Layout {
  padding: px(3);
  background-color: color(white);
}

.RoomCardRow {
  position: relative;

  .Layout {
    position: relative;
    flex: 1;

    .Title {
      margin-block-end: px(0.5);
    }

    .SpecList {
      margin-block-end: px(2);
    }
  }

  .Buttons {
    display: grid;
    padding: px(3);
    gap: px(2);
    grid-auto-rows: max-content;
    padding-inline-start: 0;

    .Dropdown {
      z-index: 1;
    }

    .Toggle {
      &::after {
        position: absolute;
        content: '';
        inset: 0;
      }

      &[data-state='open'] {
        .ToggleIcon {
          transform: rotate(180deg);
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;

    .Image {
      width: 100%;
      height: 160px;
      object-fit: cover;
    }

    .Buttons {
      display: flex;
      justify-content: flex-end;
      padding: px(1);
    }
  }
}

.Content {
  margin-block-start: px(2);
}
