@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.InputText {
  @include font(input);

  width: 100%;
  padding: px(1) px(2);
  border: 1px solid color(placeholder);
  background-color: color(white);
  border-radius: v(radius-input);
  outline-color: currentColor;

  &::placeholder {
    color: color(placeholder);
  }

  &:disabled {
    background-color: color(white, shadow);
    color: #939393;
    cursor: not-allowed;
  }

  &.isError {
    border-color: color(red);
    background-color: color(red, light);
    outline-color: color(red);
  }
}
