@import '../../../../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../../../../assets/styles/abstracts/functions';
@import '../../../../../../assets/styles/abstracts/mixins';

.AddProductOverview {
  display: flex;
  flex-direction: column;

  .ModalContent {
    display: flex;
    overflow: auto;
    flex: 1;
    flex-direction: column;
  }

  .UpperWrapper {
    position: relative;
    height: 60px;
    z-index: 1;

    @include media-breakpoint-down(sm) {
      height: 230px;
    }
  }

  .FilterWrapper {
    position: absolute;
  }

  .Filters {
    position: fixed;
    display: flex;
    width: calc(100% - 2 * px(10));
    flex-direction: row;

    @include media-breakpoint-down(sm) {
      width: calc(100% - 2 * px(4));
      flex-direction: column;

      .FilterGroup {
        margin: 0 !important;
      }
    }

    .FilterGroup {
      flex: 1;
      margin: 0 px(1);

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      .Label {
        margin-bottom: px(1);
        font-weight: bold;
      }
    }
  }

  .Products {
    position: relative;
    overflow: auto;
    flex: 1;
    margin-top: px(4);

    &.isLoading {
      opacity: 0.5;
    }

    .Product {
      margin-bottom: px(2);
    }
  }
}
