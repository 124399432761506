@import '../../../assets/styles/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/lists';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/mixins/grid';

@import '../../../assets/styles/abstracts/functions';
@import '../../../assets/styles/abstracts/mixins';

.ContactCard {
  padding: px(3) px(4);

  .BreakingLine {
    height: 1px;
    margin: px(2) 0;
    background-color: color(gray, light);
  }

  .Item {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      margin-right: px(1);
    }
  }

  .MobileGroup {
    @include media-breakpoint-down(sm) {
      gap: px(2);
      grid-auto-flow: row;
      grid-template-columns: unset;
      grid-template-rows: repeat(auto-fit, minmax(0, max-content));
    }
  }
}
